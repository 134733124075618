import React, { useState, useRef } from "react";
import { spacePositions } from "./constants";

const MonopolyBoard = ({
  currentPosition,
  targetPositions,
  onSpaceClick,
  isSelectingStart,
  isSelectingTargets,
  isAdjustingPosition,
  isCalculated,
  token,
  onRollClick,
  onPreviousClick,
  currentStepIndex,
  calculatedPath,
}) => {
  const [hoveredSpace, setHoveredSpace] = useState(null);
  const boardRef = useRef(null);

  const handleClick = (index) => {
    if (!isCalculated || isAdjustingPosition || isSelectingTargets) {
      onSpaceClick(index);
    }
  };

  return (
    <div className="board-container" ref={boardRef}>
      <div className="board-wrapper">
        <img
          src="/MGs_Board.svg"
          alt="Monopoly Board"
          className="board-image"
        />
        <svg viewBox="0 0 1000 1000" className="board-svg">
          {spacePositions.map((pos, index) => (
            <React.Fragment key={index}>
              <rect
                className={`
                  rotated-tile
                  ${hoveredSpace === index ? "hovered" : ""}
                  ${isAdjustingPosition ? "adjusting" : ""}
                  ${isSelectingTargets ? "selecting-targets" : ""}
                  ${
                    isCalculated && !isAdjustingPosition && !isSelectingTargets
                      ? "calculated"
                      : ""
                  }
                `}
                x={pos.x}
                y={pos.y}
                width={pos.width}
                height={pos.height}
                fill={
                  currentPosition === index
                    ? "rgba(252, 3, 202, 0.8)"
                    : targetPositions.includes(index)
                    ? "rgb(255, 20, 147, 0.6)"
                    : "transparent"
                }
                stroke={
                  isSelectingStart || isSelectingTargets || isAdjustingPosition
                    ? "Purple"
                    : "transparent"
                }
                strokeWidth={
                  isSelectingStart || isSelectingTargets || isAdjustingPosition
                    ? "3"
                    : "0"
                }
                onClick={() => handleClick(index)}
                onMouseEnter={() => setHoveredSpace(index)}
                onMouseLeave={() => setHoveredSpace(null)}
                style={{
                  cursor:
                    isCalculated && !isAdjustingPosition && !isSelectingTargets
                      ? "default"
                      : "pointer",
                }}
              />
              {currentPosition === index && (
                <image
                  className="token-tile"
                  x={pos.x}
                  y={pos.y}
                  width={pos.width}
                  height={pos.height}
                  href={token}
                  style={{
                    cursor:
                      isCalculated &&
                      !isAdjustingPosition &&
                      !isSelectingTargets
                        ? "default"
                        : "pointer",
                  }}
                  onClick={() => handleClick(index)}
                />
              )}
            </React.Fragment>
          ))}
        </svg>
      </div>
      {isCalculated && (
        <div className="roll-controls">
          <button
            className="roll-button"
            onClick={onRollClick}
            disabled={currentStepIndex === calculatedPath.length - 1}
          >
            ROLL
          </button>
          <button
            className="previous-button"
            onClick={onPreviousClick}
            disabled={currentStepIndex === -1}
          >
            PREVIOUS
          </button>
        </div>
      )}
    </div>
  );
};

export default MonopolyBoard;
