export const handleUndoLastRoll = (
  rolls,
  setRolls,
  positions,
  setPositions,
  setCurrentPosition,
  setUsedMultipliers,
  startingPosition,
  currentMultiplier
) => {
  if (rolls.length === 0) {
    return;
  }

  setRolls((prevRolls) => {
    // Find the last roll for the current multiplier
    let lastRollIndex = -1;
    let lastGroupIndex = -1;
    for (let i = prevRolls.length - 1; i >= 0; i--) {
      const groupIndex = prevRolls[i].findIndex(
        (roll) => roll.multiplier === currentMultiplier
      );
      if (groupIndex !== -1) {
        lastRollIndex = groupIndex;
        lastGroupIndex = i;
        break;
      }
    }

    if (lastRollIndex === -1) {
      return prevRolls;
    }

    const updatedRolls = [...prevRolls];
    const lastGroup = [...updatedRolls[lastGroupIndex]];
    lastGroup.splice(lastRollIndex, 1);

    if (lastGroup.length === 0) {
      updatedRolls.splice(lastGroupIndex, 1);
    } else {
      updatedRolls[lastGroupIndex] = lastGroup;
    }

    // Update positions
    setPositions((prev) => {
      const newPositions = { ...prev };
      const prevRoll = updatedRolls
        .flat()
        .findLast((r) => r.multiplier === currentMultiplier);

      if (prevRoll) {
        newPositions[currentMultiplier] = prevRoll.to;
      } else {
        newPositions[currentMultiplier] = startingPosition;
      }

      return newPositions;
    });

    // Set the current position
    const lastKnownPosition =
      updatedRolls.flat().findLast((r) => r.multiplier === currentMultiplier)
        ?.to || startingPosition;
    setCurrentPosition(lastKnownPosition);

    // Update used multipliers
    const usedMultipliersSet = new Set(
      updatedRolls.flat().map((roll) => roll.multiplier)
    );
    setUsedMultipliers(usedMultipliersSet);

    return updatedRolls;
  });
};
